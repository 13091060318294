import React from "react"
import styled from "styled-components"
import { breakpoints, measure, media } from "../styles"
import { Footer } from "../blocks/Footer"
import { SecondaryHero } from "../blocks/hero/secondary/SecondaryHero"
import { ContentContainer } from "../components/ContentContainer"
import { graphql, useStaticQuery } from "gatsby"
import Image, { GatsbyImageProps } from "gatsby-image"
import { PageCommon } from "../components/PageCommon"
import { BackToTopButton } from "../components/BackToTopButton"
import { useLanguage } from "../utils/useLanguage"

import { i18n } from "../content/courses/i18n"

const Content = styled.div`
  & > * + * {
    margin-top: 64px;

    ${media.smallerThan(breakpoints.large)} {
      margin-top: 48px;
    }
  }
`

export const Text = styled.div`
  li:not(:last-child) {
    margin-bottom: ${measure.measure16};
  }

  ${media.smallerThan(breakpoints.medium)} {
    padding-left: 16px;
    padding-right: 16px;
  }
`

const StyledImage = styled(Image).attrs({
  imgStyle: {
    objectFit: "cover",
    objectPosition: "center top",
  },
})<GatsbyImageProps>`
  height: 0;
  width: 100%;
`

type TextListProps = {
  title: string
  items: string[]
}

const TextList = ({ title, items }: TextListProps) => (
  <Text>
    <h2>{title}</h2>
    <ul>
      {items.map(text => (
        <li>{text}</li>
      ))}
    </ul>
  </Text>
)

export default () => {
  const language = useLanguage()
  const content = i18n[language]
  const data = useStaticQuery(query)
  return (
    <>
      <PageCommon />
      <SecondaryHero />
      <ContentContainer maxWidth="medium">
        <Content>
          <Text>
            <h1>{content.courses.title}</h1>
            {content.courses.content.map(text => (
              <p>{text}</p>
            ))}
          </Text>
          <StyledImage
            fluid={data.lessonImage.childImageSharp.fluid}
            style={{
              paddingTop: "50%",
            }}
          />
          <TextList
            title={content.program.title}
            items={content.program.content}
          />
          <StyledImage
            fluid={data.notebookImage.childImageSharp.fluid}
            style={{
              paddingTop: "50%",
            }}
          />
          <TextList
            title={content.techniques.title}
            items={content.techniques.content}
          />
        </Content>
      </ContentContainer>
      <Footer />
      <BackToTopButton />
    </>
  )
}

export const query = graphql`
  fragment FluidImage on File {
    childImageSharp {
      fluid {
        base64
        tracedSVG
        aspectRatio
        srcWebp
        srcSetWebp
        originalName
      }
    }
  }

  query {
    lessonImage: file(relativePath: { eq: "courses/lesson.jpg" }) {
      ...FluidImage
    }
    notebookImage: file(relativePath: { eq: "courses/notebook.jpg" }) {
      ...FluidImage
    }
    content: allMarkdownRemark(
      filter: { fields: { page: { eq: "courses" } } }
    ) {
      edges {
        node {
          fields {
            id
            language
          }
          html
        }
      }
    }
  }
`
