export const i18n = {
  es: {
    courses: {
      title: "Formación para profesores",
      content: [
        "He impartido cursos de formación para profesores de idiomas sobre el uso de las historias en clase, en los Centros de Formación del Profesorado de Collado Villalba y Majadahonda (Madrid).",
        "Puedo diseñar un plan de trabajo a la medida de tus necesidades, con actividades prácticas y divertidas para fomentar la comunicación y la creatividad en clase.",
      ],
    },
    program: {
      title: "Programa",
      content: [
        "Las historias en nuestra vida cotidiana y cómo usarlas en clase.",
        "Contar historias para comunicar y aprender.",
        "La narración en el mundo de hoy.",
        "El papel del oyente.",
        "Historias para mejorar la comprensión, la memoria, el ritmo, la entonación, la creatividad, la imaginación y la capacidad de improvisación.",
        "Historias para conocernos a nosotros mismos y a los demás.",
        "La importancia de un cuerpo flexible y relajado.",
        "Uso e interpretación del lenguaje no verbal.",
        "La mirada en la comunicación auténtica.",
        "Aprender a escuchar.",
        "La expresividad a través de la voz y la actitud.",
        "La importancia del silencio.",
      ],
    },
    techniques: {
      title: "Técnicas trabajadas en el curso",
      content: [
        "Estrategias para romper el hielo.",
        "El mimo y la gesticulación.",
        "Visualización de escenas.",
        "Historias compartidas.",
        "Representación de historias en grupo.",
        "Contar y re-contar historias.",
        "El principio y el final de las historias.",
        "Práctica de producción de historias inspiradas en música y en fotos/pinturas.",
        "Técnicas para escribir y hablar con creatividad",
      ],
    },
  },
  en: {
    courses: {
      title: "Teacher-training courses",
      content: [
        "I have taught some courses for language teachers in the teacher-training centres of Villalba and Majadahonda (Madrid) about the use of stories in the language class.",
        "I can design a module adapted to your needs, with plenty of activities that are practical and fun, to develop communication and creativity in class.",
      ],
    },
    program: {
      title: "Program",
      content: [
        "How to use the stories of our daily life in a language class.",
        "Telling stories to communicate and learn.",
        "Storytelling in the world today.",
        "The role of the listener.",
        "Stories to improve understanding, memory, rhythm, intonation, creativity, imagination and improvisation.",
        "Stories to get to know ourselves and the others.",
        "How to develop a flexible and relaxed body.",
        "Use and interpretation of body language.",
        "The look in genuine communication.",
        "Learning to listen.",
        "Expressiveness through voice and attitude.",
        "The importance of silence.",
      ],
    },
    techniques: {
      title: "Techniques learned in the course",
      content: [
        "Icebreaking strategies.",
        "Miming and gesticulation.",
        "Visualizing scenes.",
        "Sharing stories.",
        "Roleplaying stories in groups.",
        "Telling and re-telling stories.",
        "The beginning and the end of stories.",
        "Story-writing practice inspired by music and pictures.",
        "How to write and speak creatively.",
      ],
    },
  },
}
